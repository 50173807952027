<template>
  <section class="p-5">
    <h3 class="text-left">Gallery</h3>
    <!-- <p class="text-left">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat lacinia
      morbi placerat bibendum varius bibendum fusce eget proin. Eget
      pellentesque fermentum, id et ipsum.
    </p> -->
    <div class="items mt-5">
      <!-- <div
        class="item mr-5"
        v-for="(item, index) in items"
        :key="index"
        @mouseover="handleMouseOver(index)"
        @mouseleave="handleMouseLeave(index)"
      >
        <img
          :src="require(`../../assets/images/${item.src}`)"
          :alt="item.title"
        />
        <div class="read-more" v-if="item.active">
          {{ item.title }}
        </div>
      </div> -->
      <cool-light-box
        :items="items"
        :index="index"
        @close="index = null"
        :useZoomBar="true"
        :fullScreen="true"
      >
      </cool-light-box>
      <div class="images-wrapper">
        <div
          class="image"
          v-for="(image, imageIndex) in items"
          :key="imageIndex"
          @click="index = imageIndex"
          :style="{
            backgroundImage: 'url(' + image.src + ')',
          }"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import { galleryRef } from "../../config/firebase";

export default {
  components: {
    CoolLightBox,
  },
  data() {
    return {
      items: [],
      index: null,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const snapshot = await galleryRef.get();
      snapshot.forEach((doc) => {
        this.items.push({
          id: doc.id,
          ...doc.data(),
        });
      });
    },
    handleMouseOver(index) {
      this.items[index].active = true;
    },
    handleMouseLeave(index) {
      this.items[index].active = false;
    },
  },
};
</script>

<style scoped>
section {
  background-color: var(--primary-color);
  color: #fff;
}

.images-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.images-wrapper .image:not(:last-child) {
  margin-right: 1.1rem;
}

.images-wrapper .image {
  width: 32%;
  height: 300px;
  background-size: cover;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 3rem;
}

/* .items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.item {
  width: 33.33%;
  height: 400px;
  object-fit: cover;
} */

p {
  width: 60%;
}

img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

/* .item {
  position: relative;
}

.read-more {
  position: absolute;
  height: 80px;
  background-color: var(--secondary-color);
  cursor: pointer;
  color: #fff;
  display: grid;
  place-items: center;
  bottom: 0;
  transition: width 1s;
  border-bottom-left-radius: 20px;
  padding: 1rem;
}

.item:hover .read-more {
  width: auto;
} */

@media only screen and (max-width: 600px) {
  section {
    padding: 2rem !important;
  }

  p {
    width: 100%;
    font-size: 14px;
  }

  h3 {
    font-size: 20px;
  }

  /* .item {
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 1rem;
  } */
}
</style>