<template>
  <div class="links">
    <banner :description="description" :title="title" class="banner"></banner>
    <all-links class="all-links"></all-links>
    <footer-comp></footer-comp>
  </div>
</template>

<script>
import Banner from "../components/Common/Banner.vue";
import FooterComp from "../components/Footer.vue";
import AllLinks from "../components/Links/AllLinks.vue";
export default {
  components: { Banner, AllLinks, FooterComp },
  data() {
    return {
      title: "Some Useful Links",
      description:
        "",
    };
  },
};
</script>

<style scoped>
.all-links {
  padding: 2rem 4rem;
}

.banner {
  margin-top: 3rem;
}

@media only screen and (max-width: 600px) {
  .all-links {
    padding: 2rem;
  }

  .banner {
    margin-top: 0;
  }
}
</style>