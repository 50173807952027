<template>
  <section>
    <b-row>
      <b-col cols="12" md="6">
        <img src="../assets/images/logo/logo.png" alt="MEU" width="100px" />
        <p class="mt-3">
          With a vibrant history of over 25 years of activities both within the
          Refinery and in its affiliate, MEU has earned a name for it as a
          distinct trade union in its class.
        </p>
      </b-col>
      <b-col cols="12" md="3">
        <h4>Quick Links</h4>
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/about">About Us</a>
          </li>
          <li>
            <a href="/about#administration">Administration</a>
          </li>
          <li>
            <a href="/about#gallery">Gallery</a>
          </li>
          <li>
            <a href="/useful-links">Links</a>
          </li>
          <li>
            <a href="/reports">Reports</a>
          </li>
        </ul>
      </b-col>
      <b-col cols="12" md="3">
        <h4>Contact Information</h4>
        <ul>
          <li>
            <b-icon icon="map-fill" />
            <span>
              Kuthethoor P.O. Via Katipalla, Mangaluru. India - 575030
            </span>
          </li>
          <li>
            <b-icon icon="envelope-fill" />
            <span>mrplmlr@mrpl.co.in</span>
          </li>
          <li>
            <b-icon icon="telephone-fill" />
            <span>(91-824)2270400</span>
          </li>
        </ul>
      </b-col>
    </b-row>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
section {
  background-color: #0d2c4a;
  padding: 2rem 4rem;
  text-align: left;
}

p {
  width: 70%;
}
p,
span {
  color: #fff;
}

.b-icon {
  color: var(--secondary-color);
  margin-right: 1rem;
}

h4 {
  color: #fff;
  margin-bottom: 2rem;
}

ul {
  padding-left: 0;
}

li {
  list-style-type: none;
  margin-bottom: 1rem;
}

li > a {
  color: #fff;
}

@media only screen and (max-width: 600px) {
  section {
    padding: 2rem;
  }

  p {
    width: 100%;
    text-align: center;
    font-size: 14px;
  }

  img {
    width: 70px;
    margin: 0 auto;
    display: block;
  }

  h4 {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  li {
    font-size: 14px;
  }

  .col-md-3 {
    margin-top: 2rem;
    text-align: center;
  }
}
</style>