<template>
  <div class="login">
    <b-row>
      <b-col cols="12" md="5">
        <a href="/" >
          <img
            src="../assets/images/logo/logo.svg"
            alt="MEU Logo"
            class="p-4"
          />
        </a>
        <div class="form">
          <h3>Login</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipisci</p>
          <form>
            <label>Email Address</label>
            <input
              type="email"
              class="form-control"
              placeholder="Email Address"
            />
            <label>Password</label>
            <input
              type="password"
              class="form-control"
              placeholder="Password"
            />
            <b-btn class="mt-3">Login</b-btn>
          </form>
        </div>
      </b-col>
      <b-col cols="12" md="7" v-if="!mobile">
        <img
          src="../assets/images/login-banner.svg"
          alt="Login"
          class="banner"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import helperMixin from "../mixins/helper";

export default {
  mixins: [helperMixin],
};
</script>

<style scoped>
.login {
  height: 100vh;
}

.row {
  height: 100%;
}

.form {
  padding: 1rem 5rem;
}

form {
  margin-top: 4rem;
}

h3 {
  color: var(--primary-color);
}

label {
  color: var(--primary-color);
  font-size: 18px;
}

input {
  margin-bottom: 2rem;
  height: 50px;
}

.btn {
  background-color: var(--secondary-color);
  color: white;
  font-size: 18px;
  border: 0px;
  width: 160px;
}

.btn:hover {
  background-color: transparent;
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}

.banner {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 600px) {
  .form {
    padding: 2rem;
  }

  h3,
  p {
    text-align: center;
  }

  p {
    font-size: 14px;
  }

  label {
    font-size: 14px;
  }

  .btn {
    margin: 0 auto;
    display: block;
    font-size: 14px;
  }
}
</style>