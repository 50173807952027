<template>
  <div class="home">
    <div class="tagline">
      <h1>{{ title }}</h1>
      <p class="mt-5">{{ description }}</p>
      <div class="mt-5 actions">
        <b-btn variant="primary" class="know-more mr-4" link to="/about">
          Know More
        </b-btn>
        <b-btn variant="secondary" class="login" @click="goto()"> Login </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      loginPortalLink: "https://portal.meu.org.in",
      title: "MRPL Employee Union",
      description:
        "MRPL employees union is a organization created for the purpose of securing improvements in pay, benefits, working conditions, or social and political status through collective bargaining. MEU is intent on maintaining and improving the conditions of their members such as attaining better wages and benefits, improving working conditions, improving safety standards, establishing complaint procedures, developing rules governing status of employees (rules governing promotions, just-cause-conditions for termination) and protecting the integrity of their trade through the increased bargaining power wielded by solidarity among the employees. MEU is affiliated to the Petro & Gas workers Federation of India. (PGWFI)",
    };
  },
  methods: {
    goto() {
      window.open(this.loginPortalLink, "_blank");
    },
  },
};
</script>

<style scoped>
.home {
  background-image: url("../assets/images/meu-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 90vh;
  background-position: bottom;
  box-shadow: inset 0 0 0 2000px rgba(196, 196, 196, 0.2);
}

.tagline {
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}

h1 {
  color: #fff;
}

.know-more {
  background-color: transparent;
  border: 1px solid var(--secondary-color);
  width: 160px;
}

.know-more:hover {
  background-color: var(--secondary-color);
  color: #fff;
}

.login {
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  width: 160px;
}

.login:hover {
  background-color: transparent;
  color: var(--secondary-color);
}

@media only screen and (max-width: 600px) {
  .tagline {
    width: 100%;
  }

  h1 {
    font-size: 26px;
  }

  p {
    margin: 0 1rem;
    font-size: 14px;
  }
}
</style>
