<template>
  <section>
    <div class="rule">
      <div class="sl-no">1</div>
      <div class="item">
        <h3>Name</h3>
        <p>
          <span>(a)</span>
          he name of the Union shall be MRPL Employees Union and it shall in the
          following rules, be referred to as Union.
        </p>
      </div>
      <div class="item">
        <h3>Registered Office</h3>
        <p>
          <span>(b)</span>
          The registered office of the Union is situated at M.R.P.L. Shopping
          Complex, Kuthethoor, Post Katipalla, Mangalore Taluk - 575030 (D.K.)
          Karnataka State. The Registered Office may be shifted to other
          suitable place which the Executive Committee of the Union may decide
          from time To time and any change of the location /address shall be
          Communicated to the Deputy Registrar of Trade Unions Mangalore-575001,
          D.K. within Fourteen days of such Change in its location/address.
        </p>
      </div>
    </div>
    <div class="rule">
      <div class="sl-no">2</div>
      <div class="item">
        <h3>Objects</h3>
        <p>
          <span>(a)</span>
          To organize the employees employed in Mangalore Refinery &
          Petrochemicals Ltd. Kuthethoor, Mangalore-574149 at the Factory.
        </p>
        <p>
          <span>(b)</span>
          To regulate the relations between the employer and employees and to
          represent, correspond and negotiate with the employer or when
          necessary have recourse to legal and constitutional ways for the
          settlement of any disputes that may arise between the employer and the
          employees.
        </p>
        <p>
          <span>(c)</span>
          To promote, safeguard and protect the legitimate interests and rights
          of the members employees and to secure complete organization of the
          work force.
        </p>
        <p>
          <span>(d)</span>
          To redress the genuine and legitimate grievances of the member
          employees.
        </p>
        <p>
          <span>(e)</span>
          To try to secure to improve the living conditions of the employees.
        </p>
        <p>
          <span>(f)</span>
          To promote among the mernber employees spirit of dedication, sense of
          dutY, social responsibilities, good conduct, co-operation and ensure
          proper, honest and effrcient service from member employees to the
          industry and members of the public.
        </p>
        <p>
          <span>(g)</span>
          To provide assistance to the member employees during their old age,
          sickness, un employment, infirmity and death.
        </p>
        <p>
          <span>(h)</span>
          To foster, develop and maintain cordial industrial relations between
          the ernployer and the member employees based on sound and equitable
          principles.
        </p>
      </div>
    </div>
    <div class="rule">
      <div class="sl-no">3</div>
      <div class="item">
        <h3>Membership</h3>
        <p>
          <span>(a)</span>
          Ordinary membership is open to all those who are directly and
          regularly employed by M/s Mangalore Refinery & Petrochemicals Ltd., at
          its factory, or any of the factory throughout India.
        </p>
        <ul>
          <li>
            <span>i</span>
            He/she has attained the age of eighteen years.
          </li>
          <li>
            <span>ii</span>
            He/she submits an application in the prescribed form for declaring
            to accept the Constitution and Rules of the Union and abide by them,
            as also other rules, bye-laws and the decisions as may from time to
            time be framed and decided by the Executive Committee or the General
            Body of the Union.
          </li>
          <li>
            <span>iii</span>
            He/she pays the prescribed admission fee of Rs.25l- (Rupees twenty
            five only) and monthly membership fee (subscription)which shall be
            Rs.50/- (Rupees Fifty only) per month.
          </li>
          <li>
            <span>iv</span>
            The Executive Committee of the Union shall have the right to reject
            any application for membership without assigning any reason
            whatsoever.
          </li>
          <li>
            <span>v</span>
            A member running in arrears of his/her subscription for a period of
            three occasions shall be declared as a defaulter and in the event
            ofhis/her not clearing up the affears within one month of such
            declaration, he/she shall cease to be a member of the Union and his/
            her name shall be removed from the Union. He/she may, however,
            rejoin on payment of arrears and fresh admission fee and fine.
            Amount of fine may be decided by the Executive Committee which may
            not exceed Rs. 500/- (Rupees five hundred only).
          </li>
          <li>
            <span>vi</span>
            A member ceases to be a member of the Union by submitting his/her
            rosignation in writing made to the Gsnoral Seoretary of the Union or
            by retirement or by death or by becoming a defaulter as referred
            above in clause (v) of this rule 3(a).
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
h4 {
  color: var(--primary-color);
}

h3 {
  color: var(--secondary-color);
}

h6,
span {
  color: var(--secondary-color);
}

p {
  text-align: justify;
}

.item {
  margin-bottom: 2rem;
}

.rule {
  margin-bottom: 3rem;
  position: relative;
  padding: 0 3rem;
}

.sl-no {
  color: var(--secondary-color);
  font-size: 70px;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
}

li {
  list-style-type: none;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 600px) {
  h3 {
    font-size: 18px;
  }

  p,
  li {
    font-size: 14px;
  }

  section {
    width: 100%;
  }

  .sl-no {
    font-size: 40px;
  }

  .rule {
    padding: 0 1rem;
  }
}
</style>