<template>
  <div>
    <about-us-banner
      class="banner"
      :description="description"
      :path="path"
      :title="title"
    ></about-us-banner>
    <history class="history"></history>
    <footer-comp></footer-comp>
  </div>
</template>

<script>
import AboutUsBanner from "../components/Common/AboutUsBanner.vue";
import FooterComp from "../components/Footer.vue";
import History from "../components/History/History.vue";
export default {
  components: { AboutUsBanner, History, FooterComp },
  data() {
    return {
      path: "About Us/ History",
      title: "History of Unionism",
      description:
        "With a vibrant history of over 25 years of activities both within the Refinery and in its affiliate, MEU has earned a name for it as a distinct trade union in its class.",
    };
  },
};
</script>

<style scoped>
.banner {
  margin-top: 3rem;
}

.history {
  padding: 4rem;
}

@media only screen and (max-width: 600px) {
  .banner {
    margin-top: 0;
  }

  .history {
    padding: 2rem;
  }
}
</style>