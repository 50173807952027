<template>
  <b-row>
    <!-- <b-col
      cols="12"
      md="4"
      v-for="(item, index) in items"
      :key="index"
      class="mb-5"
    > -->
    <ul>
      <report-card
        :item="item"
        v-for="(item, index) in items"
        :key="index"
      ></report-card>
    </ul>
    <!-- </b-col> -->
  </b-row>
</template>

<script>
import { reportsRef } from "../../config/firebase";
import ReportCard from "./ReportCard.vue";
export default {
  components: { ReportCard },
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      reportsRef.get().then((snapshot) => {
        this.items = [];
        snapshot.forEach((doc) => {
          this.items.push({
            id: doc.id,
            ...doc.data(),
          });
        });
      });
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .col-md-4 {
    width: 100%;
    margin-bottom: 1rem !important;
  }
}
</style>