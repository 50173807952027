<template>
  <section class="px-5">
    <div
      class="outer"
      v-for="(item, index) in items"
      :key="index"
      @mouseover="handleMouseOver(index)"
      @mouseleave="handleMouseLeave(index)"
    >
      <div class="inner">
        <img
          :src="require(`../../assets/images/${item.image}`)"
          :alt="item.title"
          width="100%"
        />
        <div
          class="read-more read-more-alt"
          v-if="item.active"
          @click="goto(item.link)"
        >
          Read More<b-icon icon="chevron-right"></b-icon>
        </div>
        <div class="read-more" v-else>
          <b-icon icon="chevron-right" class="h3"></b-icon>
        </div>
      </div>
      <h5>{{ item.title }}</h5>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          image: "constitution.png",
          title: "Constitution",
          active: false,
          link: "/constitution",
        },
        {
          image: "rules-and-byelaws.png",
          title: "Rules and Byelaws",
          active: false,
          link: "/rules",
        },
        {
          image: "organisational-structure.png",
          title: "Organisational Structure",
          active: false,
          link: "/structure",
        },
      ],
    };
  },
  methods: {
    handleMouseOver(index) {
      this.items[index].active = true;
    },
    handleMouseLeave(index) {
      this.items[index].active = false;
    },
    goto(link) {
      this.$router.push(link);
    },
  },
};
</script>

<style scoped>
section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

h5 {
  text-align: center;
  color: var(--primary-color);
  margin-top: 1rem;
}

img {
  cursor: pointer;
}

.inner {
  position: relative;
}

.inner:hover {
  box-shadow: inset 0 0 0 2000px rgba(16, 55, 92, 0.2);
  opacity: 0.5;
}

.read-more {
  position: absolute;
  width: 80px;
  height: 80px;
  background-color: var(--secondary-color);
  cursor: pointer;
  color: #fff;
  display: grid;
  place-items: center;
  bottom: 0;
  transition: width 1s;
}

.read-more-alt {
  width: 160px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
}

@media only screen and (max-width: 600px) {
  section {
    padding: 1rem !important;
  }

  .outer {
    margin-bottom: 1rem;
  }

  h5 {
    font-size: 1rem;
  }
}
</style>