<template>
  <div class="about">
    <history class="history" id="history"></history>
    <options class="mt-5 options"></options>
    <div class="administration" id="administration">
      <h1>Administration</h1>
      <p class="mt-3">
        The Administration shall consist of Office Bearers,
        Executive Committe and the Representation in committees.
      </p>

      <h3 class="mt-5" id="office-bearers">Office Bearers</h3>
      <!-- <p>Ltetur adipiscing elit. Volutpat lacinia morbi placerat</p> -->
      <office-bearers class="mt-5"></office-bearers>

      <h3 class="mt-5" id="executive-committe">Executive Committe</h3>
      <!-- <p>Ltetur adipiscing elit. Volutpat lacinia morbi placerat</p> -->
      <executive-committe class="mt-5"></executive-committe>

      <h3 class="mt-5" id="representation-in-committees">
        Representation in committees
      </h3>
      <!-- <p>Ltetur adipiscing elit. Volutpat lacinia morbi placerat</p> -->
      <representation-in-committees class="mt-5"></representation-in-committees>
    </div>
    <gallery class="mt-5" id="gallery"></gallery>
    <footer-comp></footer-comp>
  </div>
</template>

<script>
import ExecutiveCommitte from "../components/About/ExecutiveCommitte.vue";
import Gallery from "../components/About/Gallery.vue";
import History from "../components/About/History.vue";
import OfficeBearers from "../components/About/OfficeBearers.vue";
import Options from "../components/About/Options.vue";
import RepresentationInCommittees from "../components/About/RepresentationInCommittees.vue";
import FooterComp from "../components/Footer.vue";
export default {
  name: "About",
  components: {
    History,
    Options,
    OfficeBearers,
    ExecutiveCommitte,
    RepresentationInCommittees,
    Gallery,
    FooterComp,
  },
};
</script>

<style scoped>
.about {
  margin-top: 4rem;
}

.history,
.options,
.administration {
  margin: 4rem 4rem 0 4rem;
}

h1 {
  color: var(--primary-color);
}

h3 {
  color: var(--secondary-color);
}

.administration {
  margin-top: 10rem;
  text-align: center;
}

p {
  width: 60%;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .about {
    margin-top: 0;
  }

  .history,
  .options,
  .administration {
    margin: 1rem;
  }

  h1 {
    font-size: 24px;
  }

  p {
    width: 100%;
    font-size: 14px;
  }

  h3 {
    font-size: 20px;
  }
}
</style>
