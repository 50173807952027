export default {
  data() {
    return {
      //
      mobile: false,
    };
  },
  methods: {
    //
    isMobile() {
      this.mobile = window.screen.width <= 600 ? true : false;
    },
  },
  computed: {
    //
  },
  created() {
    //
    this.isMobile();
  },
  mounted() {
    //
  },
};
