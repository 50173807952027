<template>
  <section>
    <div class="overlay"></div>
    <div class="content">
      <span>{{ path }}</span>
      <h1 class="my-4">{{ title }}</h1>
      <p>{{ description }}</p>
      <b-btn class="download-pdf mt-4" v-if="pdf" @click="downloadPdf">
        Download {{ title }} PDF
        <b-icon icon="download" class="ml-3"></b-icon>
      </b-btn>
    </div>
  </section>
</template>

<script>
export default {
  props: ["path", "title", "description", "pdf"],
  methods: {
    downloadPdf() {
      window.open(this.pdf, "_blank");
    },
  },
};
</script>

<style scoped>
section {
  background-image: url("../../assets/images/history-banner.jpeg");
  position: relative;
  height: 400px;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.overlay {
  position: absolute;
  background: rgba(16, 55, 92, 0.8);
  width: 100%;
  height: 100%;
}

.content {
  color: #fff;
  width: 54%;
  padding: 0 5rem;
  height: 100%;
  position: absolute;
  top: 25%;
}

.download-pdf {
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}

.download-pdf:hover {
  background-color: transparent;
  color: var(--secondary-color);
}

@media only screen and (max-width: 600px) {
  section {
    height: 270px;
  }

  .content {
    width: 100%;
    padding: 1rem;
    top: 0;
  }

  h1 {
    font-size: 1.5rem;
  }

  p {
    font-size: 12px;
  }

  .btn {
    margin-top: 0 !important;
  }
}
</style>