<template>
  <section>
    <b-row>
      <b-col cols="12" md="3" v-for="(item, index) in items" :key="index">
        <team-card :item="item"></team-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { officeBearersRef } from "../../config/firebase";
import TeamCard from "../Common/TeamCard.vue";
export default {
  components: { TeamCard },
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const snapshot = await officeBearersRef.get();
      snapshot.forEach((doc) => {
        this.items.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      this.reOrderData();
    },
    reOrderData() {
      const sortingArr = [
        "President",
        "Vice President",
        "General President",
        "Joint secretery",
        "Tresurer",
      ];

      const orderMap = new Map();
      sortingArr.forEach((post, index) => {
        orderMap.set(post, index);
      });

      this.items.sort((a, b) => {
        const postA = a.post;
        const postB = b.post;

        const orderA = orderMap.get(postA);
        const orderB = orderMap.get(postB);

        return orderA - orderB;
      });
    },
  },
};
</script>

<style scoped>
</style>