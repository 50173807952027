<template>
  <section>
    <table>
      <tr>
        <th><h4>SL No</h4></th>
        <th><h4>Existing Provision</h4></th>
        <th><h4>Amended Provision</h4></th>
        <th><h4>Reason for Amendment</h4></th>
      </tr>
      <tr>
        <td><h5>1</h5></td>
        <td>
          <div class="item">
            <h6>Name:</h6>
            <div>
              <p>
                <span>(a)</span>
                The name of the Union shall be MRPL Employees Union and it shall
                in the following rules, be referred to as Union.
              </p>
            </div>
          </div>
        </td>
        <td>No change</td>
        <td>Not applicable</td>
      </tr>
      <tr>
        <td></td>
        <td>
          <div class="item">
            <h6>Registered Office:</h6>
            <div>
              <p>
                <span>(b)</span>
                The registered ofFtce of the Union is situated at Clo. Costas
                Cottage, Ashoknagar Post, Mangalore- 575006 (D.K.), Karnataka
                state on temporary basis. The Registered Office may be shifted
                to other suitable place which the Executive Committee of the
                Union may decide from time To time and any change of the
                location /address shall be Communicated to the Deputy Registrar
                of Trade Unions Mangalore-575001, D.K. within Fourteen days of
                such Change in its location/address.
              </p>
            </div>
          </div>
        </td>
        <td>
          The registered office of the Union is situated at M.R.P.L. Shopping
          Complex, Kuthethoor, Post Katipalla, Mangalore Taluk - 575030 (D.K.)
          Karnataka State. The Registered Office may be shifted to other
          suitable place which the Executive Committee of the Union may decide
          from time to time and any change of the location /address shall be
          Communicated to the Deputy Registrar of Trade Unions Mangalore-575001,
          D.K. within fourteen days of such change in its location/address.
        </td>
        <td>
          Registered Office address was temporary which was shifted to a place
          near the Refinery for the sake of convenience of members.
        </td>
      </tr>
      <tr>
        <td><h5>2</h5></td>
        <td>
          <div class="item">
            <h6>Objects:</h6>
            <div>
              <p>The objects of the Union shall be as follows:</p>
            </div>
          </div>
        </td>
        <td>No change</td>
        <td>Not applicable</td>
      </tr>
      <tr>
        <td></td>
        <td>
          <div class="item">
            <p>
              <span>(a)</span>
              To organize the employees employed in Mangalore Refinery &
              Petrochemicals Ltd. Kuthethoor, Mangalore-574149 at the Factory.
            </p>
          </div>
        </td>
        <td>No change</td>
        <td>Not applicable</td>
      </tr>
      <tr>
        <td></td>
        <td>
          <div class="item">
            <p>
              <span>(b)</span>
              To regulate the relations between the employer and employees and
              to represent, correspond and negotiate with the employer or when
              necessary have recourse to legal and constitutional ways for the
              settlement of any disputes that may arise between the employer and
              the employees.
            </p>
          </div>
        </td>
        <td>No change</td>
        <td>Not applicable</td>
      </tr>
      <tr>
        <td></td>
        <td>
          <div class="item">
            <p>
              <span>(c)</span>
              To promote, safeguard and protect the legitimate interests and
              rights of the members employees and to secure complete
              organization of the work force.
            </p>
          </div>
        </td>
        <td>No change</td>
        <td>Not applicable</td>
      </tr>
      <tr>
        <td></td>
        <td>
          <div class="item">
            <p>
              <span>(d)</span>
              To redress the genuine and legitimate grievances of the member
              employees.
            </p>
          </div>
        </td>
        <td>No change</td>
        <td>Not applicable</td>
      </tr>
      <tr>
        <td></td>
        <td>
          <div class="item">
            <p>
              <span>(e)</span>
              To try to secure to improve the living conditions of the
              employees.
            </p>
          </div>
        </td>
        <td>No change</td>
        <td>Not applicable</td>
      </tr>
      <tr>
        <td></td>
        <td>
          <div class="item">
            <p>
              <span>(f)</span>
              To promote among the mernber employees spirit of dedication, sense
              of dutY, social responsibilities, good conduct, co-operation and
              ensure proper, honest and effrcient service from member employees
              to the industry and members of the public.
            </p>
          </div>
        </td>
        <td>No change</td>
        <td>Not applicable</td>
      </tr>
      <tr>
        <td></td>
        <td>
          <div class="item">
            <p>
              <span>(g)</span>
              To provide assistance to the member employees during their old
              age, sickness, un employment, infirmity and death.
            </p>
          </div>
        </td>
        <td>No change</td>
        <td>Not applicable</td>
      </tr>
      <tr>
        <td></td>
        <td>
          <div class="item">
            <p>
              <span>(h)</span>
              To foster, develop and maintain cordial industrial relations
              between the ernployer and the member employees based on sound and
              equitable principles.
            </p>
          </div>
        </td>
        <td>No change</td>
        <td>Not applicable</td>
      </tr>
      <tr>
        <td></td>
        <td>
          <div class="item">
            <p>
              <span>(i)</span>
              To publish books, periodicals, pamphlets, circulars and news
              letters containing informations useful to the members.
            </p>
          </div>
        </td>
        <td>No change</td>
        <td>Not applicable</td>
      </tr>
    </table>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
h4 {
  color: var(--primary-color);
}

h5 {
  color: var(--secondary-color);
}

h6,
span {
  color: var(--secondary-color);
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  padding: 8px;
  text-align: justify;
  vertical-align: top;
}

td:nth-child(2),
th:nth-child(2),
td:nth-child(3),
th:nth-child(3) {
  width: 32%;
}

td:nth-child(4),
th:nth-child(4) {
  width: 24%;
}

td:nth-child(1),
th:nth-child(1) {
  width: 8%;
}

tr > * {
  border: 1px solid var(--primary-color);
}

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: var(--primary-color);
}

@media only screen and (max-width: 600px) {
  h4 {
    font-size: 18px;
  }

  p,
  td {
    font-size: 14px;
  }

  section {
    width: 100%;
    overflow: scroll;
  }
}
</style>