<template>
  <b-row>
    <b-col cols="12" md="12">
      <ul>
        <li v-for="(item, index) in items" :key="index" class="mb-2">
          <link-card :item="item"></link-card>
        </li>
      </ul>
    </b-col>
  </b-row>
</template>

<script>
import { linksRef } from "../../config/firebase";
import LinkCard from "./LinkCard.vue";
export default {
  components: { LinkCard },
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      linksRef.get().then((snapshot) => {
        this.items = [];
        snapshot.forEach((doc) => {
          this.items.push({
            id: doc.id,
            ...doc.data(),
          });
        });
      });
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .col-md-4 {
    width: 100%;
    margin-bottom: 1rem !important;
  }
}
</style>