<template>
  <section>
    <div class="item px-5">
      <h1>History of MEU</h1>
      <p class="my-4">
        With a vibrant history of over 25 years of activities both within the
        Refinery and in its affiliate, MEU has earned a name for it as a
        distinct trade union in its class
      </p>
      <b-btn variant="primary" class="read-more" link to="/history-of-meu">
        Read More
      </b-btn>
    </div>
    <div class="item" v-if="!mobile">
      <img
        src="../../assets/images/history-banner.jpeg"
        alt="MEU history"
        width="100%"
        class="history-bg"
      />
    </div>
  </section>
</template>

<script>
import helperMixin from "../../mixins/helper";

export default {
  mixins: [helperMixin],
};
</script>

<style scoped>
section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: #fff;
}

.item:first-child {
  width: 46%;
}

.read-more {
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  width: 160px;
}

.read-more:hover {
  background-color: transparent;
  color: var(--secondary-color);
}

.history-bg {
  height: 400px;
  object-fit: cover;
}

@media only screen and (max-width: 600px) {
  section {
    flex-wrap: wrap;
  }
  .item {
    width: 100% !important;
    padding: 1rem 1.5rem !important;
  }

  h1 {
    font-size: 1.5rem;
  }

  p {
    font-size: 14px;
  }

  .history-bg {
    display: none;
  }
}
</style>