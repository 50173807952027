<template>
  <section>
    <div class="overlay"></div>
    <div class="content">
      <h1>{{ title }}</h1>
      <p class="mt-5">{{ description }}</p>
    </div>
  </section>
</template>

<script>
export default {
  props: ["title", "description"],
};
</script>

<style scoped>
section {
  background-image: url("../../assets/images/banner-new.jpg");
  position: relative;
  height: 400px;
  background-position: bottom;
}

.overlay {
  position: absolute;
  background: rgba(16, 55, 92, 0.8);
  width: 100%;
  height: 100%;
}

.content {
  color: #fff;
  width: 54%;
  padding: 0 5rem;
  /* height: 100%; */
  position: absolute;
  top: 25%;
}

@media only screen and (max-width: 600px) {
  section {
    height: 240px;
    background-size: cover;
    background-position: center;
  }

  .content {
    width: 100%;
    padding: 1rem;
    top: 0;
  }

  h1 {
    font-size: 24px;
    margin-top: 1rem;
  }

  p {
    font-size: 14px;
    margin-top: 1.5rem !important;
  }
}
</style>