<template>
  <b-card>
    <img
      :src="item.photoURL"
      :alt="item.name"
      width="100%"
      v-if="item.photoURL"
    />
    <img :src="getUiAvatar(item.name)" alt="" v-else />
    <h4 class="mt-2">{{ item.name }}</h4>
    <p class="emp-post">{{ item.designation || item.post }}</p>
  </b-card>
</template>

<script>
export default {
  props: ["item"],
  methods: {
    getUiAvatar(name) {
      return `https://ui-avatars.com/api/?background=10375c&color=fff&rounded=true&size=60&name=${name}`;
    },
  },
};
</script>

<style scoped>
.card {
  border: 0px;
}

img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

@media only screen and (max-width: 600px) {
  h4 {
    font-size: 18px;
  }

  p {
    font-size: 14px;
  }
}

.emp-post {
  color: var(--secondary-color);
  font-weight: bold;
}
</style>