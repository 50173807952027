<template>
  <div>
    <a :href="'//' + item.link" target="_blank">{{
      item.title ? item.title : item.link
    }}</a>
  </div>
</template>

<script>
export default {
  props: ["item"],
  methods: {
    cropDesc(desc) {
      if (desc.length > 80) {
        return desc.substring(0, 80) + "...";
      }
      return desc;
    },
    view(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped>

@media only screen and (max-width: 600px) {
  h4 {
    font-size: 18px;
    margin-top: 1rem !important;
  }

  p {
    font-size: 14px;
  }

  img {
    width: 40px;
  }
}
</style>