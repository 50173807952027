import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import UsefulLinks from "../views/UsefulLinks.vue";
import Reports from "../views/Reports.vue";
import HistoryOfMEU from "../views/HistoryOfMEU.vue";
import Constitution from "../views/Constitution.vue";
import Rules from "../views/Rules";
import Login from "../views/Login";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/useful-links",
    name: "UsefulLinks",
    component: UsefulLinks,
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
  },
  {
    path: "/history-of-meu",
    name: "HistoryOfMEU",
    component: HistoryOfMEU,
  },
  {
    path: "/constitution",
    name: "Constitution",
    component: Constitution,
  },
  {
    path: "/rules",
    name: "Rules",
    component: Rules,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
