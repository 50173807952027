<template>
  <section>
    <b-row>
      <b-col cols="12" md="3" v-for="(item, index) in items" :key="index">
        <team-card :item="item"></team-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { executiveCommitteRef } from "../../config/firebase";
import TeamCard from "../Common/TeamCard.vue";
export default {
  components: { TeamCard },
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const snapshot = await executiveCommitteRef.get();
      snapshot.forEach((doc) => {
        this.items.push(doc.data());
      });
      this.reOrderData();
    },
    reOrderData() {
      this.items.sort((a, b) => {
        const nameA = a.name.toLowerCase(); // Convert to lowercase for case-insensitive sorting
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        } else {
          return 0;
        }
      });
    },
  },
};
</script>

<style scoped>
</style>