<template>
  <div>
    <about-us-banner
      :description="description"
      :path="path"
      :title="title"
      :pdf="pdf"
    ></about-us-banner>
    <constitution class="constitution"></constitution>
    <footer-comp></footer-comp>
  </div>
</template>

<script>
import AboutUsBanner from "../components/Common/AboutUsBanner.vue";
import Constitution from "../components/Constitution/Constitution.vue";
import FooterComp from "../components/Footer.vue";
export default {
  components: { AboutUsBanner, Constitution, FooterComp },
  data() {
    return {
      path: "About Us/ Constitution",
      title: "Consitution",
      description: "",
      pdf: "https://firebasestorage.googleapis.com/v0/b/mrpl-f291a.appspot.com/o/gallery%2Fmeu-pdf.pdf?alt=media&token=b8d6e0c8-5d50-4462-a341-6c1475812d3c",
    };
  },
};
</script>

<style scoped>
.banner {
  margin-top: 3rem;
}

.constitution {
  padding: 4rem;
}

@media only screen and (max-width: 600px) {
  .banner {
    margin-top: 0;
  }

  .constitution {
    padding: 2rem;
  }
}
</style>