<template>
  <!-- <b-card @click="view(item.file)">
    <b-row>
      <b-col cols="12" md="4">
        <img src="../../assets/images/pdf.svg" :alt="item.title" />
      </b-col>
      <b-col cols="12" md="8">
        <h4 class="mt-2">{{ item.title }}</h4>
        <p>{{ cropDesc(item.description) }}</p>
      </b-col>
    </b-row>
  </b-card> -->
  <li>
    <a @click="view(item.file)">
      {{ cropDesc(item.title) }}
    </a>
  </li>
</template>

<script>
export default {
  props: ["item"],
  methods: {
    cropDesc(desc) {
      if (desc.length > 80) {
        return desc.substring(0, 80) + "...";
      }
      return desc;
    },
    view(file) {
      window.open(file, "_blank");
    },
  },
};
</script>

<style scoped>
.card {
  cursor: pointer;
  border: 0px;
  border: 1px solid var(--primary-color);
}

a {
  text-decoration: underline !important;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  h4 {
    font-size: 18px;
    margin-top: 1rem !important;
  }

  p {
    font-size: 14px;
  }

  img {
    width: 40px;
  }
}
</style>