<template>
  <div>
    <about-us-banner
      :description="description"
      :path="path"
      :title="title"
      :pdf="pdf"
    ></about-us-banner>
    <rules class="rules"></rules>
    <footer-comp></footer-comp>
  </div>
</template>

<script>
import AboutUsBanner from "../components/Common/AboutUsBanner.vue";
import FooterComp from "../components/Footer.vue";
import Rules from "../components/Rules/Rules.vue";
export default {
  components: { AboutUsBanner, Rules, FooterComp },
  data() {
    return {
      path: "About Us/ Rules",
      title: "Rules",
      description: "",
      pdf: "https://firebasestorage.googleapis.com/v0/b/mrpl-f291a.appspot.com/o/gallery%2Fmeu-pdf.pdf?alt=media&token=b8d6e0c8-5d50-4462-a341-6c1475812d3c",
    };
  },
};
</script>

<style scoped>
.banner {
  margin-top: 3rem;
}

.rules {
  padding: 4rem;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .banner {
    margin-top: 0;
  }

  .rules {
    padding: 2rem;
  }
}
</style>