<template>
  <b-navbar toggleable="lg" variant="#fff">
    <b-navbar-brand href="#">
      <img src="../assets/images/logo/logo.svg" alt="MEU" />
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item to="/">Home</b-nav-item>
        <b-nav-item-dropdown right text="About Us">
          <b-dropdown-item to="/history-of-meu">History of Unionism</b-dropdown-item>
          <b-dropdown-item to="/constitution">Constitution</b-dropdown-item>
          <b-dropdown-item to="/rules">Rules</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown right text="Administration">
          <b-dropdown-item to="/about" v-scroll-to="'#office-bearers'"
            >Office Bearers</b-dropdown-item
          >
          <b-dropdown-item to="/about" v-scroll-to="'#executive-committe'"
            >Executive Committe</b-dropdown-item
          >
          <b-dropdown-item
            to="/about"
            v-scroll-to="'#representation-in-committees'"
            >Representation in committees</b-dropdown-item
          >
        </b-nav-item-dropdown>
        <b-nav-item to="/about" v-scroll-to="'#gallery'">Gallery</b-nav-item>
        <b-nav-item to="/useful-links">Links</b-nav-item>
        <!-- <b-nav-item to="/reports">Reports</b-nav-item> -->
        <b-nav-item @click="login()">
          <b-btn variant="primary" class="btn-login">Login</b-btn>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  data() {
    return {
      loginPortalLink: "https://portal.meu.org.in",
    };
  },
  methods: {
    login() {
      window.open(this.loginPortalLink, "_blank");
    },
  },
};
</script>

<style scoped>
.nav-item {
  margin-right: 2rem;
}
>>> .nav-link:hover {
  color: var(--secondary-color) !important;
}
>>> .nav-link,
>>> .dropdown {
  color: var(--primary-color) !important;
  font-size: 18px;
}

.btn {
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  color: #fff;
}

.btn:hover {
  background-color: transparent;
  color: var(--secondary-color);
}

@media only screen and (max-width: 600px) {
  .navbar-collapse {
    text-align: center;
  }

  >>> .dropdown-menu {
    text-align: center !important;
  }
}
</style>